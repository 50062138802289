<template>
  <div class="app-container">
    <el-form :size="$store.getters.size" :inline="true" :model="queryParams" ref="queryParams">
      <el-form-item>
        <el-select filterable placeholder="选择仓库" v-model="queryParams.warId" clearable>
          <el-option v-for="(item, index) in warehouseOption" :label="item.label" :key="index" :value="item.value"/>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-date-picker
            v-model="queryParams.time"
            :type="'month'"
            :format="format"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions"
            placeholder="选择日期">
        </el-date-picker>
      </el-form-item>
<!--      <el-form-item>-->
<!--        <el-select v-model="queryParams.driverId" placeholder="选择司机" clearable filterable>-->
<!--          <el-option v-for="(item, index) in driverData" :label="item.driverName" :value="item.guid"-->
<!--                     :key="index"></el-option>-->
<!--        </el-select>-->
<!--      </el-form-item>-->

<!--      <el-form-item >-->
<!--        <el-select v-model="queryParams.type" placeholder="商品类型"></el-select>-->
<!--      </el-form-item>-->
      <el-form-item>
        <el-button type="primary" @click="querySearch" :loading="queryLoading">查询</el-button>
      </el-form-item>
    </el-form>
    <el-row :gutter="1.5">
      <el-col :span="24">
        <el-button :size="$store.getters.size" type="primary" icon="el-icon-download"
                   plain @click="exportExcel" v-if="$app.haveRole('delivery:freight:export')">运费导出
        </el-button>
      </el-col>
    </el-row>
    <div id="tableContent" style="width: 100%;">
      <mer-freight-month ref="merFreightMonth"/>

    </div>

  </div>
</template>

<script>
// 司机运费
    import merFreightMonth from './merFreight_month'
    import {getAllDriver} from '@/api/system/driver'
    import {exportExcel,} from '@/api/delivery/freight'

export default {
  name: "merchantFreightMonth",
  components: {merFreightMonth},
  data() {
    return {
      queryParams: {
        warId: undefined,
        type: 'merMonth',
        time: undefined,
        driverId: undefined,
        driverName: undefined,
        warName: undefined
      },
      format: 'yyyy-MM',
      warehouseOption: this.$store.getters.warehouse,
      driverData: [],
      queryLoading: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6;  //如果没有后面的-8.64e6就是不可以选择今天的
        }
      },


    }
  },
  methods: {
    querySearch() {
      if (this.queryLoading) {
        return;
      }
      this.queryLoading = true;
      this.$refs['merFreightMonth'].getTableData(this.queryParams);
    },
    exportExcel() {
      exportExcel(this.queryParams).then(({code}) => {
      })
    },
    // editFreight() {
    //   this.$prompt('请输入新的运费', '提示', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     inputPattern: /^[0-9]{1,3}(\.[0-9]{2})?$/,
    //     inputErrorMessage: '只能输入数字'
    //   }).then(({value}) => {
    //     console.log(value)
    //     var list = []
    //     this.rowData.forEach((e) => {
    //       list = list.concat(e.company)
    //     })
    //     let params = {
    //       freight: value,
    //       company: list,
    //       type: this.queryParams.type,
    //       warId: this.queryParams.warId,
    //       time: this.queryParams.time,
    //       driverId: this.queryParams.driverId,
    //     }
    //     // updateFreightAll(params).then(({code}) => {
    //     //   if (code === 0) {
    //     //     this.$message.success("操作成功")
    //     //     this.querySearch()
    //     //   }
    //     // })
    //   }).catch(() => {
    //   })
    // }
  },
  watch: {
    'queryParams.warId': function (val) {
      this.driverData = [];
      this.queryParams.driverId = undefined
      if (val) {
        getAllDriver({warId: val}).then(({code, data}) => {
          if (code === 0) {
            this.driverData = data;
          }
        })
        const data = this.warehouseOption.filter(item => item.value == val)
        this.queryParams.warName = data[0].label
      }
    },
    'queryParams.driverId': function (val) {
      if (val) {
        const data = this.driverData.filter(item => item.guid == val)
        this.queryParams.driverName = data[0].driverName
      }
    }
  }
}
</script>

<style scoped>

</style>
