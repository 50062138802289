<template>
  <div style="margin-top: 20px">
    <el-table  :data="tableData"  ref="table" highlight-current-row @rowClick="rowClick"
               show-summary :summary-method="summaryMethod" row-key="driverName"
              @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="50" align="center"></el-table-column>
      <el-table-column label="司机" prop="driverName" width="120" align="center"></el-table-column>
      <el-table-column label="开票单位" align="center">
        <el-table-column v-for="item in tableTitle" :label="item.invoiceName" :key="item.invoiceId" width="180" align="center">
          <template slot-scope="scope">
            <p>{{ scope.row.invoiceFreight[item.invoiceId] }}</p>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="合计" align="center" width="120">
          <template slot-scope="scope">
              {{moneyTotal(scope.row)}}
          </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
// 商户对应司机月数据
import {merchantMonthFreight} from '@/api/delivery/freight'

export default {
  name: "merFreightMonth",
  data() {
    return {
      tableData: [],
      tableTitle: [],
      multipleSelection: []
    }
  },
  updated() {
    this.$nextTick(() => {
      this.$refs.table.doLayout()
    })
  },
  methods: {
    /** 高亮显示 */
    cellStyle({row, columnIndex}) {
      if (row.invoiceName[columnIndex - 1]) {
        if (row.invoiceName[columnIndex - 1].cs) {
          return 'background:#FEF0F0';
        }
      }
    },
    /** 行统计 */
    moneyTotal({invoiceFreight}) {
      let result = 0;
      for(let key in invoiceFreight){
        result += invoiceFreight[key];
      }
      /*invoiceFreight.map(function (item) {
        result += item || 0;
      });*/
      return result;
    },
    /** 自定义合计 */
    summaryMethod({columns,data}) {
      let sums = [];
      if(this.tableTitle.length <= 0){
        return [];
      }
      // let totalMoney = 0
      let sum = 0;
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '';
        } else if (index === 1) {
          sums[index] = '总计';
        } else if (index === columns.length-1) {
          sums[index] = sum;
        }else {
          let count = 0;
          let invoiceId=this.tableTitle[index-2].invoiceId;
          data.forEach(rowData => {

            let freightObj = rowData.invoiceFreight;
            count +=  freightObj[invoiceId] || 0;
            sums[index] = count;
          })

          sum += count;
        }
        // totalMoney += sum
      })
      // sums[columns.length - 1] = totalMoney
      return sums;
    },
    getTableData(data) {
      merchantMonthFreight(data).then(({code, data}) => {
        this.$parent.queryLoading = false
        if(code === 0){
          this.tableData = data.driverFreightData;
          this.tableTitle = data.invoiceData;
        }
      })
    },
    rowClick(row, column, event) {
      console.log(row)
      this.$parent.rowData = row
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
      this.$parent.rowData = val
    }
  }
}

</script>

<style scoped>

</style>
